import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { t } from 'ab18n'
import { Icon, Menu, Badge } from 'antd'

import { DollarPoints } from 'components'
import PointsModal from "./PointsModal"
const NavBar = ({
  auth,
  logout,
  store,
  walletMovements,
  fetchWalletMovements,
  loading,
}) => {
  const [visible, setVisible] = useState(false)
  
  const hideModal = () => {
    setVisible(false)
  }
  
  const onClick = (id) => {
    fetchWalletMovements(id)
    setVisible(true)
  }
  const { wallets, cart } = store
  const setPoints = () => {
    const typeWallet = {Gold: '#f6a244', Silver: '#bbbbbb'}
    return wallets.map(item => {
      const { type } = item

      return (
        <Menu.Item key={item.type} onClick={() => onClick(item.id)}>
          <span className="submenu-title-wrapper">
            <Badge overflowCount={10}>
              <DollarPoints typeColor={typeWallet[type]}/>
              <span>{item.balanceAvailable}</span>
            </Badge>
          </span>
        </Menu.Item>
      )})
  }

  return (
    <div className="nav-bar">
      <Menu mode="horizontal">
        {
          auth
          && auth.data
          && !auth.data.isStoreAdmin
          && [
            store.wallets && setPoints(),
            <Menu.Item key={3}>
              <Link to="/store/cart">
              <span className="submenu-title-wrapper">
                <Badge overflowCount={10} count={cart.length}>
                  <Icon type="shopping-cart" />
                </Badge>
              </span>
              </Link>
            </Menu.Item>
          ]
        }
        <Menu.SubMenu
          title={(
            <Fragment>
            <span className="user-name">
              <span className="hidden-xs">
                {auth.data && auth.data.username}
              </span>
            </span>
              <Icon type="user" />
            </Fragment>
          )}
        >
          <Menu.Item onClick={logout}>
            <Icon type="close-circle" theme="filled" /> {t('auth.logout')}
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
      <PointsModal
        visible={visible}
        walletMovements={walletMovements}
        hideModal={hideModal}
        loading={loading}
        wallets={wallets}
      />
    </div>
  )
}

export default NavBar
