import {
  all, takeLatest, put, call
} from 'redux-saga/effects'
import { t, c } from 'ab18n'
import { notification } from 'antd'

import errorsFormat from 'shared/errors-format'
import history from 'shared/history'
import api from 'services/api'
import { actions, types } from './actions'

const setNotification = (message, duration = null) => (
  notification.error({ message, duration })
)

function errorsFormatException(error, message, setErrors = null) {
  const { response: { data: { errors }, status } } = error
  if (status === 500) {
    setNotification(message)
  } else if (setErrors) {
    if (errors.products) {
      setNotification(t('cart.notification.add.product'))
      setErrors(errors)
    } else {
      errorsFormat(error, message)
    }
  } else {
    errorsFormat(error, message)
  }
}

function* fetchSaga() {
  yield put(actions.setLoading(true))
  yield put(actions.setLoadingKits(true))
  try {
    const response = yield call(
      api.get,
      '/store/kits',
    )
    yield put(actions.setAll(response.data))
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('general.notification.error'))
  } finally {
    yield put(actions.setLoading(false))
    yield put(actions.setLoadingKits(false))
  }
}

function* fetchPointsSaga() {
  try {
    const response = yield call(api.get, '/store/wallets')
    yield put(actions.setWallets(response.data))
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('wallets.notification.error'))
  }
}

function* fetchOrdersSaga() {
  yield put(actions.setLoading(true))
  try {
    const response = yield call(api.get, 'store/orders')
    yield put(actions.setOrders(response.data))
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('orders.notification.error'))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* fetchOrderSaga(action) {
  const { meta: { order } } = action
  yield put(actions.setLoading(true))
  try {
    const response = yield call(api.get, `store/orders/${order}`)
    yield put(actions.setOrder(
      Object.assign(response)
    ))
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('orders.notification.error'))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* fetchCarriersSaga(action) {
  const { meta } = action

  yield put(actions.setCarriers({ data: [], loading: true }))
  try {
    const response = yield call(
      api.get,
      `/store/cart/shipping-methods${meta ? meta.queryString : ''}`
    )
    const data = response.map(item => ({
      id: item.code,
      title: item.name,
      hasInsurance: item.hasInsurance,
      info: item.description,
      requiredShippingInfo: item.requiredShippingInfo,
      description: `${
        item.price ? c(item.price) : ''
      }`,
      price: item.price,
    }))
    yield put(actions.setCarriers({ data, loading: false }))
  } catch (error) {
    if (error.response.status !== 409) {
      console.error(error)
      errorsFormat(error, t('general.notification.error'))
      yield put(actions.setCarriers({ data: [], loading: false }))
    } else {
      history.push('/store/kits')
    }
  }
}

function* fetchPaymentMethodsSaga() {
  yield put(actions.setPaymentMethods({ data: [], loading: true }))
  try {
    const response = yield call(api.get, '/store/cart/monthly-installments')
    const data = response.map((item, key) => ({
      id: item.monthlyInstallment,
      title: `${t('checkout.monthlyInstallments')} ${key + 1}`,
      description: item.name,
    }))
    yield put(actions.setPaymentMethods({ data, loading: false }))
  } catch (error) {
    if (error.response.status !== 409) {
      console.error(error)
      errorsFormat(error, t('general.notification.error'))
      yield put(actions.setPaymentMethods({ data: [], loading: false }))
    } else {
      history.push('/store/kits')
    }
  }
}

function* fetchCartSaga(action) {
  if (action && action.meta) {
    yield put(actions.setLoading(true))
  }
  yield put(actions.fetchPoints())
  try {
    const response = yield call(api.get, '/store/cart')
    yield put(actions.setCart(response.kits.map(item => ({
      ...item,
      quantity: +item.quantity || 1,
    }))))
    yield put(actions.setCartOrigin(response.shippingOrigin || null))
    yield put(actions.setCartType(response.type || null))

    delete response.kits
    delete response.shippingOrigin
    delete response.type
    yield put(actions.setCartProperties(response || null))
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('general.notification.error'))
  } finally {
    if (action && action.meta) {
      yield put(actions.setLoading(false))
    }
  }
}

function* removeItemCartSaga(action) {
  yield put(actions.setLoading(true))
  
  try {
    const response = yield call(
      api.delete,
      `/store/cart/remove-kit/${action.meta.id}`
    )
    yield put(actions.setRemoveItemCart(action.meta.id))
    yield put(actions.setCartOrigin(response.shippingOrigin || null))
    yield put(actions.setCartType(response.type || null))

    delete response.kits
    delete response.shippingOrigin
    delete response.type
    yield put(actions.setCartProperties(response || null))
    yield put(actions.fetchPoints())
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('general.notification.remove.error'))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* updateItemCartSaga(action) {
  const {
    meta: {
      id, updateData, after, setErrors
    }
  } = action

  yield put(actions.setLoading(true))
  try {
    yield call(api.put, `/store/cart/update-kit/${id}`, updateData)
    if (after) {
      after()
    }
  } catch (error) {
    console.error(error)
    errorsFormatException(
      error,
      t('cart.notification.update.error'),
      setErrors
    )
  } finally {
    yield put(actions.fetchCart())
    yield put(actions.fetch())
    yield put(actions.setLoading(false))
  }
}

function* addToCartSaga(action) {
  const { payload, meta: { after, setErrors } } = action

  yield put(actions.setLoading(true))
  try {
    yield call(api.post, '/store/cart/add-kit', payload)
    yield put(actions.fetchCart())
    if (after) {
      after()
    }
    yield put(actions.fetch())
  } catch (error) {
    console.error(error)
    errorsFormatException(
      error,
      t('cart.notification.add.error'),
      setErrors
    )
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* checkoutSaga(action) {
  const { payload, meta: { after } } = action

  yield put(actions.setLoading(true))
  try {
    yield call(api.post, 'store/cart/checkout', payload)
    yield put(actions.fetchCart())
    history.push('/store/orderplaced')
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('checkout.notification.error'))
    if (after) {
      after()
    }
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* updatePriceSaga() {
  yield put(actions.setLoading(true))
  try {
    const response = yield call(api.post, 'store/cart/update-price')
    yield put(actions.setCart(response.kits.map(item => ({
      ...item,
      quantity: +item.quantity || 1,
    }))))
    yield put(actions.setCartOrigin(response.shippingOrigin || null))
    yield put(actions.setCartType(response.type || null))
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('general.notification.error'))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* rePurchaseSaga(action) {
  const { payload } = action

  yield put(actions.setLoading(true))
  try {
    yield call(api.post, `store/orders/${payload}/repurchase`)
    yield put(actions.fetchCart())
    history.push('/store/checkout')
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('checkout.notification.error'))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* getDiscountSaga(action) {
  const { payload } = action

  yield put(actions.setLoading(true))
  try {
    yield call(
      api.post,
      '/store/cart/discount/verify',
      payload
    )
    yield put(actions.fetchCart())
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('general.notification.error'))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* searchByProductSaga(action) {
  const { meta } = action

  yield put(actions.setLoading(true))
  try {
    const response = yield call(
      api.get,
      `/store/kits?productName=${meta}`,
    )
    yield put(actions.setAll(response.data))
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('general.notification.error'))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* notifyMeSaga(action) {
  const { payload } = action

  try {
    yield call(
      api.post,
      `/store/products/${payload}/stock/notify-me`,
    )
    notification.success({
      message: t('store.notification.notifyMe.success'),
      duration: 2,
    })
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('store.notification.notifyMe.error'))
  }
}

export default function* () {
  yield all([
    takeLatest(types.FETCH, fetchSaga),
    takeLatest(types.FETCH_POINTS, fetchPointsSaga),
    takeLatest(types.FETCH_CART, fetchCartSaga),
    takeLatest(types.FETCH_ORDERS, fetchOrdersSaga),
    takeLatest(types.FETCH_ORDER, fetchOrderSaga),
    takeLatest(types.REMOVE_ITEM_CART, removeItemCartSaga),
    takeLatest(types.UPDATE_ITEM_CART, updateItemCartSaga),
    takeLatest(types.ADD_TO_CART, addToCartSaga),
    takeLatest(types.FETCH_CARRIERS, fetchCarriersSaga),
    takeLatest(types.FETCH_PAYMENT_METHODS, fetchPaymentMethodsSaga),
    takeLatest(types.CHECKOUT, checkoutSaga),
    takeLatest(types.UPDATE_PRICE, updatePriceSaga),
    takeLatest(types.RE_PURCHASE, rePurchaseSaga),
    takeLatest(types.GET_DISCOUNT, getDiscountSaga),
    takeLatest(types.SEARCH_BY_PRODUCT, searchByProductSaga),
    takeLatest(types.NOTIFY_ME, notifyMeSaga),
  ])
}
